
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Head } from '@electro/shared-ui-components';
import { HideWhenSiteUnderMaintenance } from '@electro/consumersite/src/components';
import useTranslation from 'next-translate/useTranslation';
import { featureFlagShowGoogleSignIn } from '@electro/consumersite/src/utils/envFeatureFlags';
import { LoginV2, LoginWithLink } from '@electro/consumersite/src/components/Login';
export default function SignUpPage() {
    const { t } = useTranslation('common');
    return (<div className="flex flex-col h-screen bg-gradient-mesh justify-center">
      <Head title={t('seo.page.title.sign_up')} description={t('seo.page.description.sign_up')}/>

      <HideWhenSiteUnderMaintenance showMaintenanceScreen>
        <main className="px-2 max-h-screen overflow-scroll">
          {featureFlagShowGoogleSignIn ? (<LoginV2 continueWithEmailPath="/sign-up/magic/link" showTerms showBusinessLink/>) : (<LoginWithLink showTerms showBusinessLink/>)}
        </main>
      </HideWhenSiteUnderMaintenance>
    </div>);
}

    async function __Next_Translate__getStaticProps__192048b8300__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/sign-up/magic/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192048b8300__ as getStaticProps }
  